<template>
  <div>
    <!-- <b-row>
      <b-col
        v-for="item, key in valuesEnum"
        :key="key"
        class="border-1"
        md="2"
      >
        <b-card class="text-center">
          <h5 :class="key === 'canceled' ? 'text-danger' : ''">
            {{ item }}
          </h5>
          <p :class="key === 'canceled' ? 'text-danger' : ''">
            {{ valuesFields[key] || 0 | value }}
          </p>
        </b-card>
      </b-col>
    </b-row> -->

    <b-card>

      <slot name="filters" />

      <b-table
        :current-page="page"
        :per-page="10"
        class="text-center"
        :items="list"
        :fields="[
          'ec',
          { key: 'establishment', label: 'Estabelecimento' },
          { key: 'amount', label: 'tpv'},
          { key: 'paymented', label: 'pago' },
          { key: 'antecipated', label: 'Antecipado'}
        ]"
      >
        <template
          #cell(amount)="item"
        >
          {{ item.item.amount | value }}
        </template>

        <template
          #cell(paymented)="item"
        >
          {{ item.item.paymented | value }}
        </template>

        <template
          #cell(antecipated)="item"
        >
          {{ item.item.antecipated | value }}
        </template>
      </b-table>

      <b-pagination
        v-model="page"
        :total-rows="list.length"
        :per-page="12"
        align="center"
        size="sm"
        class="my-2"
      />
    </b-card>
  </div>
</template>

<script>
import {
    BCard, BRow, BCol, BTable, BPagination,
} from 'bootstrap-vue';

export default {
    components: {
        BCard, BRow, BCol, BTable, BPagination,
    },

    props: {
        list: {
            type: Array,
            default: () => [],
        },

    },

    data() {
        return {
            page: 1,
            valuesEnum: {
                amount: 'Bruto',
                acquirerAmount: 'Adquirente',
                netAmount: 'Liquido',
                paymented: 'Pago',
                antecipated: 'Antencipado',
                canceled: 'Cancelado',
            },
        };
    },

    computed: {
        valuesFields() {
            const keys = Object.keys(this.valuesEnum);

            return this.list.reduce((acc, item) => {
                // eslint-disable-next-line no-restricted-syntax
                for (const key of keys) {
                    acc[key] = (acc[key] ?? 0) + +item[key];
                }
                return acc;
            }, {});
        },
    },
};
</script>
